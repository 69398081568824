/**
 * Do not edit this file manually!
 *
 * It is generated by scripts/dev/generate_fontawesome_manifest.rb
 * User your "fa-sold fa-star" in a view/template/component and rerun the script
 */

import { library, dom, config } from "@fortawesome/fontawesome-svg-core";
import {
  solid,
  brands,
  regular,
  light,
} from "@fortawesome/fontawesome-svg-core/import.macro";

config.autoReplaceSvg = "nest";

library.add(
  brands("dailymotion"),
  brands("deviantart"),
  brands("facebook"),
  brands("flickr"),
  brands("instagram"),
  brands("kickstarter"),
  brands("microsoft"),
  brands("slideshare"),
  brands("soundcloud"),
  brands("spotify"),
  brands("twitter"),
  brands("vimeo"),
  brands("youtube"),
  light("cloud-arrow-down"),
  light("envelope"),
  light("file"),
  light("file-audio"),
  light("file-code"),
  light("file-csv"),
  light("file-excel"),
  light("file-image"),
  light("file-lines"),
  light("file-pdf"),
  light("file-powerpoint"),
  light("file-video"),
  light("file-word"),
  light("file-zipper"),
  light("image"),
  light("mobile-screen-button"),
  light("paperclip-vertical"),
  regular("align-center"),
  regular("align-left"),
  regular("align-right"),
  regular("angle-left"),
  regular("angle-right"),
  regular("arrow-down-to-bracket"),
  regular("arrow-left"),
  regular("arrows"),
  regular("arrows-repeat"),
  regular("arrows-up-down-left-right"),
  regular("bars"),
  regular("bold"),
  regular("bolt"),
  regular("bookmark"),
  regular("box-archive"),
  regular("bullhorn"),
  regular("calendar"),
  regular("calendar-day"),
  regular("calendar-plus"),
  regular("calendar-range"),
  regular("chart-column"),
  regular("check"),
  regular("check-circle"),
  regular("check-square"),
  regular("chevron-down"),
  regular("chevron-up"),
  regular("circle"),
  regular("circle-check"),
  regular("circle-info"),
  regular("circle-notch"),
  regular("circle-play"),
  regular("circle-plus"),
  regular("clipboard"),
  regular("clock"),
  regular("clone"),
  regular("cloud-download"),
  regular("cloud-upload"),
  regular("comment"),
  regular("comment-dots"),
  regular("comment-slash"),
  regular("comments"),
  regular("desktop"),
  regular("earth-europe"),
  regular("edit"),
  regular("ellipsis"),
  regular("ellipsis-h"),
  regular("ellipsis-vertical"),
  regular("envelope"),
  regular("exchange"),
  regular("eye"),
  regular("file"),
  regular("file-code"),
  regular("file-excel"),
  regular("file-powerpoint"),
  regular("file-text"),
  regular("file-word"),
  regular("files"),
  regular("filter"),
  regular("floppy-disk"),
  regular("folder"),
  regular("folder-open"),
  regular("handshake"),
  regular("id-card"),
  regular("image"),
  regular("image"),
  regular("inbox"),
  regular("italic"),
  regular("keyboard"),
  regular("link"),
  regular("list"),
  regular("list-ol"),
  regular("list-ul"),
  regular("location-dot"),
  regular("lock"),
  regular("mailbox"),
  regular("map-location"),
  regular("map-location-dot"),
  regular("messages"),
  regular("minus-circle"),
  regular("mobile"),
  regular("paperclip-vertical"),
  regular("pen-to-square"),
  regular("pencil"),
  regular("pencil-square"),
  regular("pipe"),
  regular("plus"),
  regular("plus-circle"),
  regular("plus-square"),
  regular("power-off"),
  regular("scissors"),
  regular("search"),
  regular("share-square"),
  regular("sidebar"),
  regular("spinner"),
  regular("square"),
  regular("star"),
  regular("suitcase-rolling"),
  regular("tags"),
  regular("terminal"),
  regular("thumbs-up"),
  regular("times"),
  regular("times-circle"),
  regular("trash"),
  regular("trash-can"),
  regular("triangle-exclamation"),
  regular("underline"),
  regular("unlock"),
  regular("upload"),
  regular("user"),
  regular("user-circle"),
  regular("user-plus"),
  regular("user-xmark"),
  regular("users"),
  regular("virus"),
  regular("volume-xmark"),
  regular("xmark"),
  solid("adjust"),
  solid("align-justify"),
  solid("angle-double-down"),
  solid("angle-double-left"),
  solid("angle-double-right"),
  solid("angle-double-up"),
  solid("angle-left"),
  solid("angle-right"),
  solid("archive"),
  solid("arrow-circle-up"),
  solid("arrow-down"),
  solid("arrow-left"),
  solid("arrow-right"),
  solid("arrows"),
  solid("arrows-alt"),
  solid("arrows-repeat"),
  solid("at"),
  solid("ban"),
  solid("bar-chart"),
  solid("bars"),
  solid("bold"),
  solid("bold"),
  solid("book"),
  solid("bug"),
  solid("bullhorn"),
  solid("calendar"),
  solid("caret-down"),
  solid("caret-left"),
  solid("caret-right"),
  solid("certificate"),
  solid("check"),
  solid("check-square"),
  solid("chevron-circle-down"),
  solid("chevron-down"),
  solid("chevron-left"),
  solid("chevron-right"),
  solid("chevron-up"),
  solid("circle"),
  solid("circle-info"),
  solid("circle-question"),
  solid("clone"),
  solid("cloud-download"),
  solid("code"),
  solid("code"),
  solid("code-fork"),
  solid("coffee"),
  solid("cog"),
  solid("cogs"),
  solid("columns"),
  solid("comment"),
  solid("compress"),
  solid("desktop"),
  solid("ellipsis"),
  solid("ellipsis-h"),
  solid("envelope"),
  solid("exclamation"),
  solid("expand"),
  solid("external-link"),
  solid("eye"),
  solid("eye-slash"),
  solid("file"),
  solid("file-csv"),
  solid("file-excel"),
  solid("filter"),
  solid("flag"),
  solid("flag-checkered"),
  solid("folder"),
  solid("folder-open"),
  solid("font"),
  solid("globe"),
  solid("grip-dots"),
  solid("hashtag"),
  solid("header"),
  solid("header"),
  solid("home"),
  solid("image"),
  solid("info-circle"),
  solid("italic"),
  solid("italic"),
  solid("key"),
  solid("link"),
  solid("link"),
  solid("list"),
  solid("list"),
  solid("list-alt"),
  solid("list-ol"),
  solid("list-ul"),
  solid("lock"),
  solid("mailbox"),
  solid("map-marker"),
  solid("message-xmark"),
  solid("money-bill"),
  solid("paper-plane"),
  solid("paperclip"),
  solid("pencil"),
  solid("pencil-square"),
  solid("phone"),
  solid("pipe"),
  solid("play"),
  solid("play-circle"),
  solid("plus"),
  solid("plus-circle"),
  solid("print"),
  solid("question"),
  solid("question-circle"),
  solid("quote-left"),
  solid("quote-right"),
  solid("refresh"),
  solid("reply"),
  solid("reply-all"),
  solid("save"),
  solid("search"),
  solid("search"),
  solid("share"),
  solid("shield"),
  solid("sort"),
  solid("sort-asc"),
  solid("sort-desc"),
  solid("sort-down"),
  solid("sort-numeric-asc"),
  solid("sort-up"),
  solid("space-shuttle"),
  solid("spinner"),
  solid("square"),
  solid("square-o"),
  solid("star"),
  solid("step-backward"),
  solid("step-forward"),
  solid("strikethrough"),
  solid("tag"),
  solid("tags"),
  solid("thumbs-up"),
  solid("thumbtack"),
  solid("timer"),
  solid("times"),
  solid("times-circle"),
  solid("toggle-off"),
  solid("toggle-on"),
  solid("trash"),
  solid("underline"),
  solid("unlock"),
  solid("unlock-alt"),
  solid("user"),
  solid("user-circle"),
  solid("user-minus"),
  solid("user-plus"),
  solid("user-secret"),
  solid("user-times"),
  solid("users"),
  solid("volume-off"),
  solid("volume-xmark"),
  solid("xmark"),
);

dom.watch();
